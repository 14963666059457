import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
// import SpotlightEvent from "../components/talks/spotlight-modified"
import PastEvents from "../components/events/past_commb_talks"
import {renderRichText} from 'gatsby-source-contentful/rich-text'

const Events = ({ data }) => {
  const { contentfulTalks, pastEventsEnCa } = data;
  const talk = data.contentfulTalks
  return <div className="eventsPage talksPage">
  <Layout>
    <Seo title="COMMB Talks" />
    <div className="events_page">
      <div className="main_content_wrapper insight_page">
        <div className="spotlight_article_section">
        <div className={data.contentfulTalks.hideSpotlightEvent ? "container hideSpotlight" : "container hello"}>
            {/* <SpotlightEvent /> */}
            <div className="talksWrapper">
              {renderRichText(talk.info)}
              <p className="signupWrapperBtnTalks"><a href={talk.url} className="eventsCTABtn">{talk.label}<svg xmlns="http://www.w3.org/2000/svg" width="38.938" height="8.071" viewBox="0 0 38.938 8.071"><g transform="translate(-1192.844 -692.433)"><path data-name="Icon open-arrow-left" d="M3.561,0,0,3,3.561,5.935V3.561h34.1V2.374H3.561Z" transform="translate(1231 699.427) rotate(180)" fill="#e15b5f"></path><path data-name="Icon open-arrow-left - Outline" d="M4.061-1.076v2.95h34.1V4.061H4.061V6.995L-.781,3.008Zm33.1,3.95H3.061v-1.8L.781,3l2.28,1.877V3.061h34.1Z" transform="translate(1231 699.427) rotate(180)" fill="#e15b5f"></path></g></svg></a></p>
            </div>
              <PastEvents events={pastEventsEnCa.pastEvents} talksTitle={contentfulTalks.talksTitle} />
          </div>
        </div>
      </div>
    </div>
    </Layout>
  </div>
}

export default Events

export const query = graphql`
  query EventsPageQuery($language: String) {
    contentfulTalks(node_locale: { eq: $language }) {
      hideSpotlightEvent
      talksTitle {
        raw
      }
      info {
        raw
      }
      label
      url
    }
    pastEventsEnCa: contentfulTalks(node_locale: { eq: "en-CA" }) {
      pastEvents {
        id
        title
        image {
          file {
            url
          }
        }
        date
        externalInternalEvent
      }
    }
  }
`;