import React, { useState } from "react"
import moment from "moment"
import Modal from "react-modal"
import { renderRichText } from 'gatsby-source-contentful/rich-text'


const PastEvents = ({ events, talksTitle }) => {
  const date = d => moment(d).utcOffset('+0500').local().format("DD");
  const month = d => moment(d).utcOffset('+0500').local().format("MMMM");
  const year = d => moment(d).utcOffset('+0500').local().format("YYYY");

  const [idx, setIdx] = useState(null)
  const [modalIsOpen, setIsOpen] = useState(false)
  function openModal(i) {
    setIdx(i)
    setIsOpen(true)
  }
  function closeModal() {
    setIsOpen(false)
  }

Modal.setAppElement('#___gatsby');
  return (
    <div className="insight_card_block " onClick={() => {
      modalIsOpen && closeModal()
    }}>
      <div className="more_events_section_title">
        <div className="common_section_title">
        {renderRichText(talksTitle)}
        </div>
      </div>
      <div className="all_insight_cards">
      {events?.map((event, i) => (
        <div className="insight_card" onClick={() => openModal(i)} key={event.id}>
          <div className="recent_news_box">
            <div className="recent_news_img">
              <a
                target="_blank"
                tabIndex="0"
              >
                <img src={event.image.file.url} alt="event" />
              </a>
            </div>
            <div className="recent_news_content">
              <div className="media">

                <div className="calendar_block">
                  <h2>{date(event.date)}</h2>
                  <p>
                      {month(event.date)} {year(event.date)}
                  </p>
                </div>

                <div className="media-body">
                  <h4>{event.title}</h4>
                </div>
              </div>
            </div>
          </div>
          {modalIsOpen && <Modal
            isOpen={idx === i}
            onRequestClose={closeModal}
            bodyOpenClassName={`modalOpen videoModal`}
        >
          <iframe id="video1" width="800" height="550" src={event.externalInternalEvent} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
         <a className="closeBtnModal" onClick={closeModal}>
          {" "}
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.688"
              height="17.688"
              viewBox="0 0 17.688 17.688"
          >
              <path
              id="close-icon"
              d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
              transform="translate(-7.5 -7.5)"
              fill="#fff"
              ></path>
          </svg>
        </a>
        </Modal>}
        </div>
      ))}
      
      </div>
    </div>
  )
}

export default PastEvents




